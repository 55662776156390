define('zet-ethereum-pool/controllers/stats', ['exports', 'ember'], function (exports, _ember) {
    function _toConsumableArray(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) arr2[i] = arr[i]; return arr2; } else { return Array.from(arr); } }

    exports['default'] = _ember['default'].Controller.extend({
        applicationController: _ember['default'].inject.controller('application'),
        config: _ember['default'].computed.reads('applicationController.config'),
        intl: _ember['default'].inject.service(),

        totalHR: _ember['default'].computed('poolsComputed', {
            get: function get() {
                var totalHR = 0.0;
                this.get('poolsComputed').forEach(function (p) {
                    totalHR = totalHR + p.stats.hashrate;
                });
                return totalHR;
            }
        }),
        poolsComputed: _ember['default'].computed('model.pools', 'config', {
            get: function get() {
                var result = [];

                var mapHRHistory = function mapHRHistory(elem) {
                    return {
                        time: new Date(elem.time * 1000),
                        label: "Hashrate",
                        value: elem.hr
                    };
                };
                var mapSelectHR = function mapSelectHR(elem) {
                    return elem.hr;
                };
                var createMapBlockHistory = function createMapBlockHistory(val) {
                    return function (elem) {
                        return {
                            time: new Date(elem * 1000),
                            label: "Block found",
                            value: val
                        };
                    };
                };
                var mapBestNode = function mapBestNode(n) {
                    if (!bestNode) {
                        bestNode = n;
                    }
                    if (bestNode.height < n.height) {
                        bestNode = n;
                    }
                };

                for (var i = 0; i < this.get('config').POOLS.length; i++) {
                    var pool = {};
                    var poolConfig = this.get('config').POOLS[i];
                    var poolStats = this.get('model.pools')[i];

                    pool.stats = poolStats.stats;
                    pool.luck = poolStats.blocks.luck;

                    pool.shortName = poolConfig.shortName;
                    pool.fullName = poolConfig.fullName;
                    pool.url = poolConfig.url;
                    pool.blockTime = poolConfig.blockTime;
                    pool.reward = poolConfig.reward;

                    /*              return this.get(this.get('intl').findTranslationByKey('main.notices')) || this.get('model.main.stats.notices');*/
                    if (this.get('intl').findTranslationByKey('main.notices').slice(-2) === "Pl") {
                        pool.notices = poolStats.stats.stats.noticesPl;
                    } else {
                        pool.notices = poolStats.stats.stats.noticesEn;
                    }

                    pool.hashrateHistory = poolStats.stats.hashrateHistory.map(mapHRHistory);

                    var maxHr = Math.max.apply(Math, _toConsumableArray(poolStats.stats.hashrateHistory.map(mapSelectHR)));

                    var multip = 1000.0;
                    while (maxHr > multip * 10.0) {
                        multip *= 10.0;
                    }
                    var val = maxHr - maxHr % multip + multip;

                    pool.blockHistory = poolStats.stats.blockHistory.map(createMapBlockHistory(val));

                    var bestNode = null;
                    poolStats.stats.nodes.forEach(mapBestNode);

                    if (bestNode) {
                        pool.difficulty = bestNode.difficulty;
                        pool.height = bestNode.height;
                    } else {
                        pool.difficulty = 0;
                        pool.height = 0;
                    }

                    var epochOffset = (30000 - pool.height % 30000) * 1000 * poolConfig.blockTime;
                    pool.nextEpoch = Date.now() + epochOffset;

                    pool.hashrate = pool.difficulty / poolConfig.blockTime;

                    pool.roundShares = parseInt(poolStats.stats.stats.roundShares);

                    var percent = pool.roundShares / pool.difficulty;
                    if (!percent) {
                        percent = 0;
                    }
                    pool.roundVariance = percent.toFixed(2);

                    pool.immatureTotal = (poolStats.stats.immatureTotal ? poolStats.stats.immatureTotal : 0) + (poolStats.stats.candidatesTotal ? poolStats.stats.candidatesTotal : 0);

                    pool.ethPerGHs = poolConfig.reward * 3600000000000.0 / pool.difficulty;
                    pool.fiat = poolStats.fiat.USD ? poolStats.fiat.USD : 0;
                    pool.fiatPerGHs = (poolStats.fiat.USD ? poolStats.fiat.USD : 0) * pool.ethPerGHs;

                    result.push(pool);
                }
                return result;
            }
        })
    });
});