define('zet-ethereum-pool/router', ['exports', 'ember', 'zet-ethereum-pool/config/environment'], function (exports, _ember, _zetEthereumPoolConfigEnvironment) {

  var Router = _ember['default'].Router.extend({
    location: _zetEthereumPoolConfigEnvironment['default'].locationType
  });

  Router.map(function () {
    this.route('not-found');

    this.route('faq');
    this.route('help');
  });

  exports['default'] = Router;
});