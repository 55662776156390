define('zet-ethereum-pool/helpers/format-balance', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Helper.extend({
    intl: _ember['default'].inject.service(),
    compute: function compute(params, hash) {
      var value = params[0];
      if (!isNaN(parseFloat(value)) && isFinite(value)) {
        return this.get('intl').formatNumber(value * 0.000000001, hash);
      } else {
        return "0";
      }
    }
  });
});