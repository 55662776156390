define('zet-ethereum-pool/routes/stats', ['exports', 'ember', 'zet-ethereum-pool/config/environment'], function (exports, _ember, _zetEthereumPoolConfigEnvironment) {
    exports['default'] = _ember['default'].Route.extend({
        intl: _ember['default'].inject.service(),
        actions: {
            lookup: function lookup(login) {
                if (!_ember['default'].isEmpty(login)) {
                    return this.transitionTo('account', login);
                }
            }
        },
        model: function model() {
            var promises = [];
            var func = function func(data) {
                return _ember['default'].Object.create(data);
            };
            var funcFiat = function funcFiat(data) {
                return _ember['default'].RSVP.hash({ "USD": data.quotes.USD.price });
            };
            var fail = function fail() {
                return _ember['default'].RSVP.hash({ "USD": 0 });
            };
            for (var i = 0; i < _zetEthereumPoolConfigEnvironment['default'].APP.POOLS.length; i++) {
                var pool = _zetEthereumPoolConfigEnvironment['default'].APP.POOLS[i];
                promises.push(_ember['default'].RSVP.hash({
                    stats: _ember['default'].$.getJSON(pool.url + 'api/stats').then(func),
                    blocks: _ember['default'].$.getJSON(pool.url + 'api/blocks').then(func),
                    //                fiat: Ember.$.getJSON("https://min-api.cryptocompare.com/data/price?fsym=" + pool.ccName + "&tsyms=USD").then(func, fail)
                    //                fiat: Ember.$.getJSON("https://api.coinmarketcap.com/v1/ticker/" + pool.cmcName + "/").then(funcFiat, fail)
                    // fiat: Ember.$.getJSON("https://api.coinpaprika.com/v1/tickers/" + pool.paprikaName).then(funcFiat, fail)
                    fiat: fetch("https://api.coinpaprika.com/v1/tickers/" + pool.paprikaName).then(function (response) {
                        return response.json();
                    }).then(funcFiat, fail)
                    // fiat: Ember.RSVP.hash({"USD": 0})
                }, pool.shortName));
            }

            return _ember['default'].RSVP.hash({ pools: _ember['default'].RSVP.all(promises, "stats") });
        },
        activate: function activate() {
            this._super();
            window.scrollTo(0, 0);
        },
        setupController: function setupController(controller, model) {
            this._super(controller, model);
            //        Ember.run.later(this, this.refresh, config.APP.ApiRefreshInterval);
            _ember['default'].$(function () {
                _ember['default'].$('[data-toggle="tooltip"]').tooltip();
            });
        }
    });
});