define('zet-ethereum-pool/helpers/add-timestamp', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Helper.extend({
    intl: _ember['default'].inject.service(),
    compute: function compute(params) {
      var value = params[0];
      var timestamp = Date.now();
      if (!isNaN(parseInt(value)) && isFinite(value)) {
        return timestamp + parseInt(value);
      } else {
        return timestamp;
      }
    }
  });
});