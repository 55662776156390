define('zet-ethereum-pool/controllers/application', ['exports', 'ember', 'zet-ethereum-pool/config/environment'], function (exports, _ember, _zetEthereumPoolConfigEnvironment) {
  exports['default'] = _ember['default'].Controller.extend(Object.defineProperties({}, {
    config: {
      get: function get() {
        return _zetEthereumPoolConfigEnvironment['default'].APP;
      },
      configurable: true,
      enumerable: true
    }
  }));
});