define('zet-ethereum-pool/helpers/format-hashrate', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Helper.extend({
        intl: _ember['default'].inject.service(),
        compute: function compute(params) {
            var hashrate = params[0];
            var i = 0;
            var units = ['H/s', 'KH/s', 'MH/s', 'GH/s', 'TH/s', 'PH/s'];
            while (hashrate > 1000) {
                hashrate = hashrate / 1000;
                i++;
            }
            return this.get('intl').formatNumber(hashrate, { maximumFractionDigits: 2 }) + ' ' + units[i];
        }
    });
});