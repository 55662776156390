define('zet-ethereum-pool/app', ['exports', 'ember', 'zet-ethereum-pool/resolver', 'ember-load-initializers', 'zet-ethereum-pool/config/environment'], function (exports, _ember, _zetEthereumPoolResolver, _emberLoadInitializers, _zetEthereumPoolConfigEnvironment) {

  var App = undefined;

  _ember['default'].MODEL_FACTORY_INJECTIONS = true;

  App = _ember['default'].Application.extend({
    rootElement: "#root",
    modulePrefix: _zetEthereumPoolConfigEnvironment['default'].modulePrefix,
    podModulePrefix: _zetEthereumPoolConfigEnvironment['default'].podModulePrefix,
    Resolver: _zetEthereumPoolResolver['default']
  });

  (0, _emberLoadInitializers['default'])(App, _zetEthereumPoolConfigEnvironment['default'].modulePrefix);

  exports['default'] = App;
});