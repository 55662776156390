define('zet-ethereum-pool/controllers/faq', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Controller.extend({
    applicationController: _ember['default'].inject.controller('application'),
    config: _ember['default'].computed.reads('applicationController.config'),
    intl: _ember['default'].inject.service(),
    queries: _ember['default'].computed('intl', {
      get: function get() {
        var _this = this;

        var N = 10;while (this.get('intl').exists('faq.' + N + '.title')) {
          N += 10;
        }
        return Array.from(Array(N).keys()).filter(function (_, idx) {
          return _this.get('intl').exists('faq.' + idx + '.title');
        });
      }
    })
  });
});