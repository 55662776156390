define('zet-ethereum-pool/helpers/with-metric-prefix', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Helper.extend({
        intl: _ember['default'].inject.service(),
        compute: function compute(params, hash) {
            var n = params[0];

            if (n < 1000) {
                return n;
            }

            var i = 0;
            var units = ['K', 'M', 'G', 'T', 'P'];
            while (n > 1000) {
                n = n / 1000;
                i++;
            }
            return this.get('intl').formatNumber(n, hash) + ' ' + units[i - 1];
        }
    });
});