define("zet-ethereum-pool/routes/application", ["exports", "ember"], function (exports, _ember) {
  exports["default"] = _ember["default"].Route.extend({
    intl: _ember["default"].inject.service(),

    beforeModel: function beforeModel() {
      var lang;
      if (window.Cookies.get("lang")) {
        lang = window.Cookies.get("lang");
      } else if (navigator.languages) {
        lang = navigator.languages[0];
      } else if (navigator.language || navigator.userLanguage) {
        lang = navigator.language || navigator.userLanguage;
      } else {
        lang = 'en';
      }
      this.get('intl').setLocale([lang.replace(/-.*/, ""), 'en', 'pl']);
    },
    actions: {
      changeLang: function changeLang(lang) {
        window.Cookies.set("lang", lang);
        this.get('intl').setLocale(lang);
        this.refresh();
      }
    }
  });
});